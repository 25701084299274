<template>
  <div>
    <b-row class="mt-5 mb-5">
      <h4 class="mr-auto">
        <b-icon icon="file-earmark-person-fill" aria-hidden="true" /> 児童の管理
      </h4>
      <b-button
        class="mr-3"
        variant="outline-primary"
        v-b-modal.modal-prevent-closing
        >新規追加
      </b-button>
    </b-row>
    <student-class-filter class="mb-5" v-model="selectedClass" />
    <b-table :items="students" :fields="fields">
      <template v-slot:cell(action)="data">
        <b-button
          v-b-modal="`deleteAlertFor${data.item.id}`"
          variant="outline-danger"
          >削除</b-button
        >
        <b-modal
          :id="`deleteAlertFor${data.item.id}`"
          title="削除"
          @ok="deleteItem(data)"
        >
          <p>「{{ data.item.name }}」を削除してもよろしいですか？</p>
        </b-modal>
      </template>
    </b-table>
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="新規追加"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="nameState"
          label="名前"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="newName"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  query,
  doc,
  where,
  documentId,
  addDoc
} from "firebase/firestore";
import StudentClassFilter from "@/components/StudentClassFilter.vue";

export default {
  name: "manageStaff",
  components: {
    StudentClassFilter
  },
  data() {
    return {
      newName: "",
      nameState: null,
      fields: ["name", "action"],
      students: [],
      selectedClass: null,
    };
  },
  methods: {
    async deleteItem(data) {
      await deleteDoc(doc(getFirestore(), `students/${data.item.id}`));
      location.reload();
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.newOfficeName = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    async handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      await addDoc(collection(getFirestore(), "students"), {
        name: this.newName
      });
      location.reload();
    }
  },
  watch: {
    async selectedClass() {
      if (!this.selectedClass) {
        this.students = [];
      }
      const db = getFirestore();
      const studentIds = (
        await getDoc(
          doc(
            db,
            `offices/${this.selectedClass.office}/classes/${this.selectedClass.class}`
          )
        )
      ).data().member;

      const snapshot = await getDocs(
        query(collection(db, "students"), where(documentId(), "in", studentIds))
      );
      this.students = snapshot.docs.map(doc => {
        return { id: doc.id, name: doc.data()["name"] };
      });
    }
  }
};
</script>
