<template>
  <div>
    <b-row class="mt-5 mb-5">
      <h4 class="mr-auto"><b-icon icon="people-fill" aria-hidden="true"/> クラスの管理</h4>
      <b-button
        class="mr-3"
        variant="outline-primary"
        v-b-modal.modal-prevent-closing
        >新規追加
      </b-button>
    </b-row>
    <b-table :items="classes" :fields="fields">
      <template v-slot:cell(action)="data">
        <b-button :href="'class/' + data.item.officeId + '/' + data.item.id" variant="outline-dark"
          >詳細</b-button
        >
        <b-button v-b-modal="`deleteAlertFor${data.item.id}`" variant="outline-danger"
          >削除</b-button
        >
        <b-modal :id="`deleteAlertFor${data.item.id}`" title="削除" @ok="deleteItem(data)">
          <p>「{{ data.item.name }}」を削除してもよろしいですか？</p>
        </b-modal>
      </template>
    </b-table>
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="新規追加"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="nameState"
          label="事業所"
          label-for="office-input"
          invalid-feedback="Office is required"
        >
          <b-form-select
            id="office-input"
            v-model="newOfficeSelected"
            :options="newOfficeOptions"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          :state="nameState"
          label="名称"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="newClassName"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { getFirestore, getDocs, deleteDoc, doc, collectionGroup, collection, addDoc } from 'firebase/firestore';

export default {
  name: "manageClass",
  data() {
    return {
      fields: ["office", "name", "action"],
      classes: [],
      newOfficeSelected: null,
      newOfficeOptions: [],
      nameState: null,
      newClassName: "",
    };
  },
  methods: {
    async deleteItem(data) {
      await deleteDoc(doc(getFirestore(), `offices/${data.item.officeId}/classes/${data.item.id}`))
      location.reload()
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.newClassName = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    async handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      await addDoc(collection(getFirestore(), `offices/${this.newOfficeSelected}/classes`), {
          name: this.newClassName,
          office_id: this.newOfficeSelected,
          office: this.newOfficeOptions.filter((item) => {
            return item.value == this.newOfficeSelected;
          })[0].text,
          member: [],
        })
      location.reload();
    },
  },
  created: async function () {
    const snapshot = await getDocs(collectionGroup(getFirestore(), "classes"))
    this.classes = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        officeId: doc.data()["office_id"],
        office: doc.data()["office"],
        name: doc.data()["name"],
      };
    });
    const offices = await getDocs(collection(getFirestore(), "offices"))
    this.newOfficeOptions = offices.docs.map((doc) => {
      return {
        value: doc.id,
        text: doc.data()["name"],
      };
    });
    this.newOfficeSelected = this.newOfficeOptions[0].value;
  },
};
</script>