<template>
  <div>
    <h4 class="mt-5 mb-5">
      <b-icon icon="file-earmark-person-fill" aria-hidden="true" />
      パーソナル分析 - <b-link :to="'/analytics/student'">リスト</b-link> /
      {{ this.student.name }}
    </h4>
    <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab
            v-for="(data, key) in skills"
            :title="`${data.title}`"
            :key="key"
          >
            <b-card :title="`スキル「${data.title}」`">
              <b-card-text>
                このスキルには以下の評価ポイントが含まれます
              </b-card-text>
              <b-card-text>
                <ol>
                  <li v-for="(viewPoint, key) in data.view_point" :key="key">
                    {{ viewPoint }}
                  </li>
                </ol>
              </b-card-text>
            </b-card>

            <Chart
              class="mt-5"
              :height="chartHeight"
              :chart-data="chartData[data.title] ? chartData[data.title] : {}"
            ></Chart>

            <h5 class="mt-5 mb-3">レッスンの一覧</h5>
            <b-card
              v-for="(lesson, key2) in lessonData[data.title]
                ? lessonData[data.title].slice().reverse()
                : []"
              :key="key2"
              no-body
              class="mt-3"
            >
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-card-body>
                  <b-icon
                    v-if="lesson.student.attendance && lesson.score != 100"
                    icon="exclamation-circle-fill"
                    font-scale="1.5"
                    variant="danger"
                    class="mr-2"
                  />
                  {{ lesson.date.toDate().toLocaleDateString() }} 実施
                  {{
                    lesson.student.attendance ? `${lesson.score}点` : "欠席"
                  }}
                  {{
                    lesson.student.attendance && lesson.score != 100
                      ? "今後の課題：" +
                        lesson.student.sessions
                          .slice(-1)[0]
                          .evaluation.reduce((acm, value, index) => {
                            return value
                              ? acm
                              : `${acm}「${lesson.skill.view_point[index]}」`;
                          }, "")
                      : ""
                  }}

                  <b-button
                    class="float-right"
                    v-b-toggle="`accordion-${key2}`"
                    v-if="
                      lesson.student.attendance ||
                        lesson.student.preMemoPath ||
                        lesson.student.sessions.filter(session => {
                          return session.memo_path;
                        }).length > 0
                    "
                    >詳細</b-button
                  >
                  <b-icon
                    class="float-right mr-3"
                    v-if="
                      lesson.student.preMemoPath ||
                        lesson.student.sessions.filter(session => {
                          return session.memo_path;
                        }).length > 0
                    "
                    icon="chat-square-text"
                    font-scale="1.5"
                  />
                </b-card-body>
              </b-card-header>
              <b-collapse
                :id="`accordion-${key2}`"
                visible
                accordion="my-accordion"
                role="tabpanel"
                v-if="
                  lesson.student.attendance ||
                    lesson.student.preMemoPath ||
                    lesson.student.sessions.filter(session => {
                      return session.memo_path;
                    }).length > 0
                "
              >
                <b-list-group>
                  <b-list-group-item v-if="lesson.student.preMemoPath">
                    <b-row class="mt-3">
                      <b-col>
                        <h5>事前メモ</h5>
                      </b-col>
                      <b-col
                        ><FIRImage :path="lesson.student.preMemoPath"
                      /></b-col>
                    </b-row>
                  </b-list-group-item>
                  <b-list-group-item
                    v-for="(session, key) in lesson.student.sessions"
                    :key="key"
                  >
                    <b-row class="mt-3">
                      <b-col>
                        <h5>セッション {{ key + 1 }}</h5>
                        <b-list-group class="mt-3">
                          <b-list-group-item
                            v-for="(viewPoint, key) in lesson.skill.view_point"
                            :key="key"
                            class="d-flex align-items-center justify-content-between"
                          >
                            <b-icon
                              :icon="session.evaluation[key] ? `circle` : `x`"
                              font-scale="1.5"
                              :variant="
                                session.evaluation[key] ? `success` : `danger`
                              "
                            />
                            {{ viewPoint }}</b-list-group-item
                          >
                        </b-list-group>
                      </b-col>
                      <b-col>
                        <FIRImage
                          v-if="session.memo_path"
                          :path="session.memo_path"
                        />
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
              </b-collapse>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import { getFirestore, doc, orderBy, query, where, getDocs, getDoc, collection } from 'firebase/firestore';
import FIRImage from "../../components/FIRImage.vue";
import Chart from "@/components/LineChart.js";

export default {
  name: "analyticsStudent",
  data() {
    return {
      student: {},
      lessonData: {},
      skills: {},
      chartHeight: 200,
      chartData: {},
    };
  },
  components: {
    FIRImage,
    Chart,
  },
  methods: {
    getScore: function (lesson) {
      return Math.floor(
        (lesson.student.sessions.slice(-1)[0].evaluation.filter((item) => {
          return item;
        }).length /
          lesson.skill.view_point.length) *
        100
      );
    },
  },
  created: async function () {
    const db = getFirestore()
    const userDoc = await getDoc(doc(db, `students/${this.$route.params.id}`))
    this.student = userDoc.data();
    this.student["id"] = userDoc.id;

    const lessonsRef = collection(db, 'lessons')
    const q = query(lessonsRef, where("class.member", "array-contains", this.student.id), orderBy('date'))
    const lessons = (await getDocs(q)).docs.filter(item => { return item.data()["skill"] });

    //含まれているスキルの種類
    this.skills = lessons
      .map((lesson) => {
        return lesson.data()["skill"];
      })
      .filter((item, index, array) => {
        return array.findIndex((item2) => item.title === item2.title) === index;
      });

    //スキル毎のレッスンデータを作成
    const lessonDataTmp = {};
    const chartDataTmp = {};
    this.skills.forEach((skill) => {
      lessonDataTmp[skill.title] = [];
      chartDataTmp[skill.title] = {
        labels: [],
        datasets: [
          {
            label: this.student.name,
            data: [],
            lineTension: 0,
            fill: true,
            borderColor: `#10375c`,
            backgroundColor: "rgba(16,55,92,0.5)",
          },
        ],
      };
    });
    const studentDataSnapshots = await Promise.all(
      lessons.map((lessonDoc) => {
        return getDoc(doc(db, `lessons/${lessonDoc.id}/students/${this.student.id}`))
      })
    );

    lessons.forEach((lesson, index) => {
      const tempLessonData = lesson.data();
      tempLessonData.id = lesson.id;
      tempLessonData.student = studentDataSnapshots[index].data();
      tempLessonData.score = this.getScore(tempLessonData);
      lessonDataTmp[tempLessonData.skill.title].push(tempLessonData);
      chartDataTmp[tempLessonData.skill.title].labels.push(
        tempLessonData.date.toDate().toLocaleDateString()
      );
      chartDataTmp[tempLessonData.skill.title].datasets[0].data.push(
        tempLessonData.student.attendance ? tempLessonData.score : null
      );
    });

    this.chartData = chartDataTmp;
    this.lessonData = lessonDataTmp;
  },
};
</script>
