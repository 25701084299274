<template>
  <b-card>
    <b-form inline>
      <label class="mr-3" for="inline-form-input-name">事業所*</label>
      <b-form-select
        id="inline-form-input-name"
        class="mr-3"
        :options="officeSelection"
        v-model="selectedOffice"
      ></b-form-select>

      <label class="mr-3" for="inline-form-input-username">クラス*</label>
      <b-form-select
        id="inline-form-input-username"
        class="mr-auto"
        :options="classSelection"
        v-model="selectedClass"
      ></b-form-select>
      <label class="mr-0">*がついている項目は必須です</label>
    </b-form>
    <b-form class="mt-2" inline>
      <label class="mr-3">期間</label>
      <datepicker
        class="mr-1"
        :format="datepickerModel.DatePickerFormat"
        :bootstrap-styling="true"
        :language="datepickerModel.ja"
        v-model="datepickerModel.fromDate"
        placeholder="日付を選択してください"
      ></datepicker>
      <label class="mr-1">〜</label>
      <datepicker
        class="mr-3"
        :format="datepickerModel.DatePickerFormat"
        :bootstrap-styling="true"
        :language="datepickerModel.ja"
        v-model="datepickerModel.toDate"
        placeholder="日付を選択してください"
      ></datepicker>
      <label class="mr-3">スキル</label>
      <b-form-select
        class="mr-auto"
        :options="skillSelection"
        v-model="selectedSkill"
      ></b-form-select>

      <b-button variant="outline-primary" class="mr-0" @click="show"
        >表示</b-button
      >
    </b-form>
  </b-card>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";
import { getFirestore, collection, getDocs, orderBy, query } from 'firebase/firestore';

export default {
  name: "classAnalyticsSearch",
  components: {
    Datepicker,
  },
  props: ["value"],
  data() {
    return {
      selectedSkill: null,
      selectedOffice: null,
      officeSelection: [],
      classes: {},
      classSelection: [],
      selectedClass: null,
      datepickerModel: {
        toDate: null,
        fromDate: null,
        DatePickerFormat: "yyyy-MM-dd",
        ja: ja,
      },
      skills: []
    };
  },
  computed: {
    skillSelection() {
      return [""].concat(this.skills.filter(skill => !skill.office || skill.office == this.selectedOffice).map(e => e.title))
    }
  },
  watch: {
    selectedOffice: function () {
      this.classSelection = this.selectedOffice
        ? this.classes[this.selectedOffice]
        : [];
    },
  },
  methods: {
    show: function () {
      const data = {
        class: this.selectedClass,
        skill: this.selectedSkill != "" ? this.selectedSkill : null
        };
      if (this.datepickerModel.fromDate != null) {
        data.fromDate = new Date(
          this.datepickerModel.fromDate.getFullYear(),
          this.datepickerModel.fromDate.getMonth(),
          this.datepickerModel.fromDate.getDate()
        );
      }
      if (this.datepickerModel.toDate != null) {
        data.toDate = new Date(
          this.datepickerModel.toDate.getFullYear(),
          this.datepickerModel.toDate.getMonth(),
          this.datepickerModel.toDate.getDate() + 1
        );
      }
      this.$emit("input", data);
    },
  },
  created: async function () {
    const db = getFirestore()
    const snapshot = await getDocs(collection(db, "offices"));
    this.officeSelection = [];
    this.classes = {};
    snapshot.forEach(async (doc) => {
      this.officeSelection.push({
        value: doc.id,
        text: doc.data()["name"],
      });
      const classesSnapshot = await getDocs(query(collection(db, `offices/${doc.id}/classes`),orderBy('name')))
      this.classes[doc.id] = classesSnapshot.docs.map((classDoc) => {
        return {
          value: classDoc.id,
          text: classDoc.data()["name"],
        };
      });
    });
    this.skills = (await getDocs(query(collection(db, "skills"), orderBy('title')))).docs.map(doc => doc.data())
  },
};
</script>
