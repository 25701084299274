import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ["cutGraph"],
  data() {
    return {
      options: {
        spanGaps: !this.cutGraph,
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "実施日"
              },
            }
          ],
          yAxes: [
            {
              ticks: {
                min: 0,
                max: 100,
              },
              scaleLabel: {
                display: true,
                labelString: "達成度(%)"
              },
            }
          ]
        },

      }
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  },
  watch: {
    cutGraph: function() {
      console.log("updated")
      this.options.spanGaps = !this.cutGraph
      this.renderChart(this.chartData, this.options)
    }
  }
}
