<template>
  <b-jumbotron bg-variant="info" text-variant="white" border-variant="dark" class="mt-5">
    <template v-slot:header>Sorry, the Page Not Found.</template>

    <template v-slot:lead>
      お探しのページが見つかりませんでした
    </template>

    <hr class="my-4" />

    <p>
      上部のバーからページを選択してアナリティクスや管理の画面に移動することができます
    </p>
  </b-jumbotron>
</template>

<script>
export default {
  name: "notFound",
};
</script>
