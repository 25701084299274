<template>
  <div>
    <h4 class="mt-5 mb-5">
      <b-icon icon="people-fill" aria-hidden="true" /> クラス分析
    </h4>

    <ClassAnalyticsSearch v-model="search" />
    <div v-show="lessonsList.length != 0">
      <b-row class="mt-5 pl-3">
        <h4 class="mr-auto">
          <span
            >{{ result.className }}
            {{
              result.fromDate || result.toDate
                ? `${result.fromDate}〜${result.toDate}`
                : "全期間"
            }}</span
          >
        </h4>
        <b-form-checkbox v-model="cutGraph" class="mr-3">
        スキル・タグで区切る
      </b-form-checkbox>
        <xlsx-workbook>
          <xlsx-sheet :collection="sheet.data" sheet-name="sheet1" />
          <xlsx-download :filename="`sst_record.xlsx`">
            <b-button class="mr-3" variant="secondary">ダウンロード</b-button>
          </xlsx-download>
        </xlsx-workbook>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12">
          <Chart
            id="chart"
            :height="chartHeight"
            :chart-data="chartData"
            :cutGraph.sync="cutGraph"
          ></Chart>
        </b-col>
      </b-row>
      <b-row>
        <ag-grid-vue
          style="width: 100%; height: 500px"
          class="ag-theme-alpine mt-3"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :gridOptions="gridOptions"
        ></ag-grid-vue>
      </b-row>
      <ClassAnalyticsLessonsList v-model="lessonsList" />
    </div>
    <EmptyCard v-show="lessonsList.length == 0" />
  </div>
</template>



<script>
import Chart from "@/components/LineChart.js";
import ClassAnalyticsSearch from "@/components/ClassAnalyticsSearch.vue";
import ClassAnalyticsLessonsList from "@/components/ClassAnalyticsLessonsList.vue";
import EmptyCard from "@/components/EmptyCard.vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";

import { getFirestore, getDocs, collection, query, orderBy, where } from 'firebase/firestore';
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
import { ClassLessons } from "../../components/ClassLessons";

export default {
  name: "analyticsClass",
  components: {
    Chart,
    EmptyCard,
    AgGridVue,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    ClassAnalyticsSearch,
    ClassAnalyticsLessonsList,
  },
  data() {
    return {
      cutGraph: true,
      selectedSkill: null,
      search: {},

      chartHeight: 200,
      chartData: {
        labels: [],
        datasets: [],
      },
      columnDefs: null,
      rowData: null,
      result: {
        className: "",
        toDate: "",
        fromDate: "",
      },
      lessons: {},
      lessonsList: [],
      gridOptions: {
        getRowStyle: (params) => {
          if (params.data.passed) {
            return { background: "#bcffdd" };
          } else {
            return null;
          }
        },
        onRowDoubleClicked: function (event) {
          window.open(`/analytics/student/${event.data.studentId}`);
        },
      },
      gridApi: null,
      gridColumnApi: null,
      sheet: {
        name: "Sheet1",
        data: null,
      },
    };
  },
  watch: {
    search: async function () {
      await this.getLessons();

      //レッスンの一覧を表示
      this.lessonsList = this.lessons.lessonsList();

      //表を表示
      this.columnDefs = this.lessons.spreadSheetColumns();
      this.rowData = this.lessons.spreadSheetRows();

      //Excel
      this.sheet.data = this.lessons.excelRows();

      //Chart
      this.chartData = this.lessons.chart();
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
  computed: {
    skills: function() {
      return Array.from(new Set(this.lessonsList.map(lesson=>lesson.skill?.title).filter(e => e)))
    },

  },
  methods: {
    getLessons: async function () {
      if (!this.search) {
        return;
      }

      const db = getFirestore()
      const collectionRef = collection(db, 'lessons')
      let ref = query(collectionRef, where("class.iid", "==", this.search.class), orderBy('date'))

      //期間の指定
      if (this.search.fromDate) {
        ref = query(ref, where("date", ">=", this.search.fromDate))
      }
      if (this.search.toDate) {
        ref = query(ref, where("date", "<", this.search.toDate))
      }

      if(this.search.skill) {
        ref = query(ref, where("skill.title", "==", this.search.skill))
      }

      //Firestoreからの取得
      const snapshot = await getDocs(ref)
      const lessons = snapshot.docs.map((doc) => {
        const tmp = doc.data();
        tmp.id = doc.id;
        return tmp;
      });

      //取得したデータの概要表示
      this.result.fromDate = this.search.fromDate?.toLocaleDateString() ?? "";
      this.result.toDate = this.search.toDate?.toLocaleDateString() ?? "";
      if (snapshot.docs.length > 0) {
        this.result.className = snapshot.docs[0].data()["class"]["name"];
      }

      //レッスン毎の生徒データの取得
      const studentLessons = await Promise.all(
        snapshot.docs.map(async (doc) =>
          (
            await getDocs(collection(db, `lessons/${doc.id}/students`))
          ).docs.map((doc2) => {
            const tmp = doc2.data();
            tmp["id"] = doc2.id;
            return tmp;
          })
        )
      );

      this.lessons = new ClassLessons(lessons, studentLessons);
      console.log(this.lessons)
    },
  },
};
</script>