<template>
  <b-card>
    <b-form inline>
      <label class="mr-3" for="inline-form-input-name">事業所</label>
      <b-form-select
        id="inline-form-input-name"
        class="mr-3"
        :options="officeSelection"
        v-model="selectedOffice"
      ></b-form-select>

      <label class="mr-3" for="inline-form-input-username">クラス</label>
      <b-form-select
        id="inline-form-input-username"
        class="mr-auto"
        :options="classSelection"
        v-model="selectedClass"
      ></b-form-select>
    </b-form>
  </b-card>
</template>


<script>
import { getFirestore, collection, getDocs, orderBy, query } from 'firebase/firestore';

export default {
  name: "classAnalyticsSearch",
  props: ["value"],
  data() {
    return {
      officeSelection: [],
      selectedOffice: {},
      classes: {},
      classSelection: [],
      selectedClass: null,
    };
  },
  watch: {
    selectedOffice: function () {
      this.classSelection = this.selectedOffice
        ? this.classes[this.selectedOffice]
        : [];
    },
    selectedClass() {
      this.$emit("input", {class: this.selectedClass, office: this.selectedOffice});
    }
  },
  created: async function () {
    const db = getFirestore()
    const snapshot = await getDocs(collection(db, "offices"));
    this.officeSelection = [];
    this.classes = {};
    snapshot.forEach(async (doc) => {
      this.officeSelection.push({
        value: doc.id,
        text: doc.data()["name"],
      });
      const classesSnapshot = await getDocs(query(collection(db, `offices/${doc.id}/classes`),orderBy('name')))
      this.classes[doc.id] = classesSnapshot.docs.map((classDoc) => {
        return {
          value: classDoc.id,
          text: classDoc.data()["name"],
        };
      });
    });
  },
};
</script>
