<template>
  <div>
    <b-row class="mt-5 mb-5">
      <h4 class="mr-auto">
        <b-icon icon="people-fill" aria-hidden="true" /> クラスの詳細 -
        <b-link :to="'/manage/class'">リスト</b-link> /
        {{ this.class.office }} / {{ this.class.name }}
      </h4>
    </b-row>
    <b-row class="mt-5 mb-5">
      <h5 class="mr-auto">メンバー</h5>
      <b-button
        class="mr-3"
        variant="outline-primary"
        v-b-modal.modal-prevent-closing
        >新規追加
      </b-button>
    </b-row>
    <b-table :items="students" :fields="fields">
      <template v-slot:cell(action)="data">
        <b-button
          v-b-modal="`deleteAlertFor${data.item.id}`"
          variant="outline-danger"
          >削除</b-button
        >
        <b-modal
          :id="`deleteAlertFor${data.item.id}`"
          title="削除"
          @ok="deleteItem(data)"
        >
          <p>「{{ data.item.name }}」を削除してもよろしいですか？</p>
        </b-modal>
      </template>
    </b-table>
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="追加"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="nameState"
          label="生徒"
          label-for="student-input"
          invalid-feedback="Student is required"
        >
          <b-form-select
            id="student-input"
            v-model="studentSelected"
            :options="studentOptions"
            required
          ></b-form-select>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { getFirestore, getDoc, getDocs, updateDoc, doc, collection, query, where, arrayUnion, arrayRemove, documentId } from 'firebase/firestore';

export default {
  name: "manageClassMember",
  data() {
    return {
      fields: ["name", "action"],
      class: { office: "", name: "" },
      students: [],
      studentSelected: null,
      studentOptions: [],
      nameState: null,
    };
  },
  methods: {
    async deleteItem(data) {
      await updateDoc(doc(getFirestore(), `offices/${this.class.officeId}/classes/${this.class.id}`), {
        member: arrayRemove(data.item.id),
      })
      location.reload();
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.newClassName = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    async handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      await updateDoc(doc(getFirestore(), `offices/${this.class.officeId}/classes/${this.class.id}`), {
        member: arrayUnion(
          this.studentSelected
        ),
      })
      location.reload();
    },
  },
  created: async function () {
    const document = await getDoc(doc(getFirestore(),
      `offices/${this.$route.params.officeId}/classes/${this.$route.params.classId}`))
    this.class = {
      id: document.id,
      student_ids: document.data()["member"],
      officeId: document.data()["office_id"],
      office: document.data()["office"],
      name: document.data()["name"],
    };

    if (this.class.student_ids.length > 0) {
      const collectionRef = collection(getFirestore(), "students")
      const q = query(collectionRef, where(documentId(), "in", this.class.student_ids))
      this.students = (await getDocs(q)).docs.map((studentDoc) => {
        return {
          id: studentDoc.id,
          name: studentDoc.data()["name"],
        };
      });
    }

    //選択肢
    const studentNotmember = await getDocs(collection(getFirestore(), "students"))
    this.studentOptions = studentNotmember.docs
      .filter((optionDoc) => {
        return !this.class.student_ids.includes(optionDoc.id);
      })
      .map((optionDoc) => {
        return {
          value: optionDoc.id,
          text: optionDoc.data()["name"],
        };
      });
    if (this.studentOptions.length > 0) {
      this.studentSelected = this.studentOptions[0].value;
    }
  },
};
</script>