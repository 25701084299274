export class StudentLesson {
    attendance;
    evaluation;
    score;
    tags;
    skill;

    constructor(lesson, tags, skill) {
        this.attendance = lesson.attendance
        this.evaluation = lesson.sessions[0]?.evaluation
        this.score = this.attendance && this.evaluation ? parseInt((this.evaluation.filter(ev => ev).length / this.evaluation.length) * 100) : null
        this.tags = tags
        this.skill = skill
    }
}

export class Student {
    id;
    name;
    lessons = [];
    spreadSheetRow = {};

    constructor(lessons, id, tags, skills) {
        this.id = id;
        this.lessons = lessons.map((lesson, index) => {
            const tmp = lesson.find(student => student.id === id)
            if (tmp) {
                this.name = tmp.name;
                return new StudentLesson(tmp, tags[index], skills[index])
            } else {
                return null
            }
        })

        //最後の三回が連続100点かどうか
        const attendLessons = this.lessons.filter(lesson => lesson ? lesson.attendance : false)
        const lastLesson = attendLessons[attendLessons.length - 1]
        const sameLessonType = attendLessons.filter(lesson => lesson.skill == lastLesson.skill && lesson.tags.toString() == lastLesson.tags.toString()).slice(-3)

        //SpreadsheetのRowの形に成形
        const rowTmp = {
            student: this.name,
            studentId: this.id,
            passed: sameLessonType.length >= 3 && sameLessonType.every(lesson => lesson ? lesson.score == 100 : false)
        }
        this.lessons.forEach((lesson, lessonIndex) => {
            rowTmp[`${lessonIndex}-score`] = lesson?.score
            lesson?.evaluation?.forEach((eva, evaIndex) => {
                rowTmp[`${lessonIndex}-${evaIndex}`] = lesson.attendance ? (eva ? "○" : "×") : "-"
            })
        })
        this.spreadSheetRow = rowTmp
    }
}

export class ClassLessons {
    lessons = [];
    studentLessons = [];
    students = [];

    constructor(lessons, studentLessons) {
        this.lessons = lessons
        this.studentLessons = studentLessons
        this.students = lessons[lessons.length - 1]?.class.member.map(id => 
            new Student(studentLessons, id, this.lessons.map(lesson => lesson.tags ?? []), this.lessons.map(lesson => lesson.skill?.title ?? "")
            )) ?? []
    }

    lessonsList = () => {
        return this.lessons
    }

    spreadSheetColumns = () => {
        const columns = [{ headerName: "目標", children: [{ headerName: "Student", field: "student" }] }];

        const viewPointList = (lesson, lessonIndex) => {
            return lesson.skill?.view_point.map((vp, vpIndex) => {
                return {
                    headerName: vpIndex + 1,
                    field: `${lessonIndex}-${vpIndex}`,
                    columnGroupShow: "closed",
                    headerTooltip: vp,
                    width: 60,
                    columnGroupShow: "closed",
                    cellStyle: function (params) {
                        if (params.value == "×") {
                            //mark police cells as red
                            return { backgroundColor: "#ffbcdd" };
                        } else {
                            return null;
                        }
                    },
                }
            }) ?? []
        }

        const lessonHeaders = this.lessons.map((lesson, lessonIndex) => {
            return {
                headerName: `${lesson.date.toDate().toLocaleDateString()}`,
                children: [{
                    headerName: lesson.skill?.title ?? "未設定",
                    children: [{
                        headerName: "スコア",
                        field: `${lessonIndex}-score`,
                        width: 130,
                        columnGroupShow: "everything else",
                    }]
                        .concat(viewPointList(lesson, lessonIndex))
                }]
            }
        })
        return columns.concat(lessonHeaders)
    }

    spreadSheetRows = () => {
        return this.students.map(student => student.spreadSheetRow)
    }

    excelRows = () => {
        /* const dateRow = {};
        const titleRow = { student: "目標" };
        const scoreRow = {};
        this.lessons.forEach((lesson, lessonIndex) => {
            dateRow[`${lessonIndex}-score`] = lesson.date.toDate().toLocaleDateString();
            titleRow[`${lessonIndex}-score`] = lesson.skill?.title ?? "未設定"
            scoreRow[`${lessonIndex}-score`] = "スコア"
            lesson.evaluation?.forEach((_, evaIndex) => {
                scoreRow[`${lessonIndex}-${evaIndex}`] = evaIndex + 1
            })
        })
        return [dateRow, titleRow, scoreRow].concat(this.spreadSheetRows()) */
        const dateRow = ["",]
        const titleRow = ["目標"]
        const scoreRow = [""]
        this.lessons.forEach((lesson, lessonIndex) => {
            dateRow.push(lesson.date.toDate().toLocaleDateString());
            titleRow.push(lesson.skill?.title ?? "未設定")
            scoreRow.push("スコア")
            lesson.skill?.view_point.forEach((_, evaIndex) => {
                dateRow.push("")
                titleRow.push("")
                scoreRow.push(evaIndex + 1)
            })
        })
        const mainData = this.spreadSheetRows().map(row => {
            const list = Object.values(row)
            list.splice(1,2)
            return list
        });
        return [dateRow, titleRow, scoreRow].concat(mainData)
    }

    chart = () => {
        const splitBySkill = (array) => {
            const newArray = []
            for(let index in this.lessons) {
                newArray.push(array[index])
                const currentSkill = this.lessons[index].skill?.title ?? ""
                const currentTag = this.lessons[index].tags?.sort() ?? []
                const nextSkill = this.lessons[parseInt(index) + 1]?.skill?.title ?? ""
                const nextTag = this.lessons[parseInt(index) + 1]?.tags?.sort() ?? []
                if(currentSkill != nextSkill || currentTag.toString() != nextTag.toString()) {
                    newArray.push(null)
                }
            }
            return newArray
        }

        const chartLabels = this.lessons.map(lesson => lesson.date.toDate().toLocaleDateString())
        const lines = this.students.map(student => student.lessons.map(lesson => lesson?.score))

        const averageLine = []
        for(let index in this.lessons) {
            const lessonScores = lines.map(line => line[index]).filter(x => x != null)
            averageLine.push(lessonScores.length ? lessonScores.reduce((prev, cur) => prev + cur) / lessonScores.length : null)
        }

        const makeDataset = (title, line, index) => {
            return {
                label: title,
                data: line,
                lineTension: 0,
                fill: false,
                borderColor: index !== null ? `hsla(${index * 40}, 70%, 50%, 0.3)` : `rgb(255,0,0)`,
              }
        }

        const chartDatasets = lines.map((line,index) => makeDataset(this.students[index].name, splitBySkill(line), index))
        chartDatasets.push(makeDataset("平均", splitBySkill(averageLine), null))

        return {
            labels: splitBySkill(chartLabels).map(x => x ? x : ""),
            datasets: chartDatasets,
        }
    }
}