<template>
  <b-navbar class="dark-background" type="dark" toggleable="sm">
    <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>
    <b-container>
      <b-navbar-brand href="/">
        <b-img :src="icon" width="30px" fluid class="mr-2"></b-img>SSTLog
      </b-navbar-brand>
      <b-navbar-nav class="ml-0 mr-auto">
        <b-nav-item-dropdown>
          <template #button-content>
            <b-icon icon="bar-chart-line-fill" aria-hidden="true" />
            アナリティクス
          </template>
          <b-dropdown-item href="/analytics/class">
            <b-icon icon="people-fill" aria-hidden="true" />
            クラス</b-dropdown-item
          >
          <b-dropdown-item href="/analytics/student">
            <b-icon icon="file-earmark-person-fill" aria-hidden="true" />
            パーソナル</b-dropdown-item
          >
        </b-nav-item-dropdown>
        <b-nav-item-dropdown>
          <template #button-content>
            <b-icon icon="gear-fill" aria-hidden="true" /> 管理
          </template>
          <b-dropdown-item href="/manage/office">
            <b-icon icon="building" aria-hidden="true" />
            事業所</b-dropdown-item
          >
          <b-dropdown-item href="/manage/class">
            <b-icon icon="people-fill" aria-hidden="true" />
            クラス</b-dropdown-item
          >
          <b-dropdown-item href="/manage/staff">
            <b-icon
              icon="person-fill"
              aria-hidden="true"
            />スタッフ</b-dropdown-item
          >
          <b-dropdown-item href="/manage/student">
            <b-icon icon="file-earmark-person-fill" aria-hidden="true" />
            児童</b-dropdown-item
          >
          <b-dropdown-item href="/manage/skill"
            ><b-icon icon="trophy-fill" aria-hidden="true" />
            スキル</b-dropdown-item
          >
          <b-dropdown-item href="/manage/tags"
            ><b-icon icon="tags-fill" aria-hidden="true" />
            タグの履歴</b-dropdown-item
          >
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <b-navbar-nav>
        <b-nav-item-dropdown :text="userName">
          <b-dropdown-item @click="signOut">{{
            shouldLogin ? "ログイン" : "ログアウト"
          }}</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-container>
  </b-navbar>
</template>

<script>
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import icon from "@/assets/icon.png";

export default {
  data() {
    return {
      icon: icon,
      userName: "",
      shouldLogin: true,
    };
  },
  methods: {
    signOut() {
      const auth = getAuth();
      signOut(auth).then(() => {
        this.$router.push("/sign-in")
      }).catch((error) => {
        // An error happened.
      });

    },
  },
  async created() {
    onAuthStateChanged(getAuth(), async (user) => {
      this.shouldLogin = user == null
      if (user) {
        const db = getFirestore()
        const docRef = doc(db, `staffs/${user.uid}`)
        const snapshot = await getDoc(docRef)
        this.userName = `ようこそ、${snapshot.data()["name"]}さん`;
      } else {
        this.userName = "ログインしてください";
      }
    })
  },
};
</script>

<style scoped>
.dark-background {
  background-color: #10375c;
}
</style>
