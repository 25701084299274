<template>
  <div>
    <h5 class="mt-5 mb-5">レッスンの一覧</h5>
    <b-table :items="lessonsList" :fields="lessonsListFields">
      <template v-slot:cell(action)="data">
        <b-button @click="showDeleteModal(data.item)" variant="outline-danger"
          >削除
        </b-button>
      </template>
      <template v-slot:cell(date)="data">
        <p>{{ data.item.date.toDate().toLocaleDateString() }}</p>
      </template>
    </b-table>
    <b-modal
      id="deleteModal"
      title="削除"
      ok-variant="danger"
      ok-ttle="削除"
      @show="deleteModalReset"
      @ok="deleteModalDelete"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <p>
          削除すると復元できません。{{
            selectedDeleteData.date
              ? selectedDeleteData.date.toDate().toLocaleDateString()
              : ""
          }}に実施された「{{
            selectedDeleteData.skill
              ? selectedDeleteData.skill.title
              : "未設定"
          }}」のレッスンを削除してもよろしいですか？
        </p>
        <p>
          確認のために、削除するにはこちらの日付「{{
            selectedDeleteData.date
              ? selectedDeleteData.date.toDate().toLocaleDateString()
              : ""
          }}」をそのまま入力してください。
        </p>
        <b-form-input
          id="name-input"
          v-model="deleteVarifyText"
          :state="deleteState"
          invalid-feedback="入力したテキストが間違っています"
        ></b-form-input>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { deleteDoc, getFirestore, doc} from 'firebase/firestore';

export default {
  props: ["value"],
  data() {
    return {
      lessonsList: this.value,
      lessonsListFields: [
        { key: "date", label: "実施日" },
        { key: "skill.title", label: "スキル" },
        { key: "tags", label: "タグ" },
        { key: "action", label: "" },
      ],
      selectedDeleteData: {},
      deleteState: null,
      deleteVarifyText: "",
    };
  },
  watch: {
    value : function() {
        this.lessonsList = this.value
    }  
  },
  methods: {
    deleteModalReset: function (event) {
      this.deleteState = null;
      this.deleteVarifyText = "";
    },
    deleteModalDelete: function (event) {
      event.preventDefault();
      this.submitDelete();
    },
    submitDelete: async function () {
      if (
        this.deleteVarifyText !=
        this.selectedDeleteData.date.toDate().toLocaleDateString()
      ) {
        this.deleteState = false;
        return;
      }
      this.deleteState = true;
      const db = getFirestore()
      await deleteDoc(doc(db, `lessons/${this.selectedDeleteData.id}`))
      this.$nextTick(() => {
        this.$bvModal.hide("deleteModal");
      });
      location.reload();
    },
    showDeleteModal: function (data) {
      this.selectedDeleteData = data;
      this.$bvModal.show("deleteModal");
    },
  },
};
</script>
