<template>
  <div>
    <h4 class="mt-5 mb-5">サインイン</h4>

    <b-form>
      <b-form-group v-if="invalidFeedback" class="text-danger">
        {{invalidFeedback}}
      </b-form-group>
      <b-form-group id="input-group-1" label="Email address:" label-for="input-1">
        <b-form-input
          id="input-1"
          v-model="form.email"
          type="email"
          required
          placeholder="Enter email"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label="Password:" label-for="input-2">
        <b-form-input
          id="input-2"
          type="password"
          v-model="form.password"
          required
          placeholder="Enter password"
        ></b-form-input>
      </b-form-group>
      <b-button variant="primary" @click="login">ログイン</b-button>
    </b-form>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword} from 'firebase/auth';

export default {
  name: "signIn",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      invalidFeedback: null
    };
  },
  methods: {
    async login(event) {
      const auth = getAuth()
      try {
        const response = await signInWithEmailAndPassword(auth, this.form.email, this.form.password)
        this.invalidFeedback = null
        location.href = "/";
      } catch (error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        this.invalidFeedback = error.message
      }
    },
  },
};
</script>