<template>
  <div>
    <h4 class="mt-5 mb-5">
      <b-icon icon="trophy-fill" aria-hidden="true" /> スキルの編集 -
      <b-link :to="'/manage/skill'">リスト</b-link> / {{ skill.title }}
    </h4>
    <form class="mb-4" ref="form" @submit.stop.prevent="handleSubmit">
      <div class="form-inline mb-3">
        <label class="mr-3">タイトル</label>
        <b-form-input
          :state="nameState"
          id="input-1"
          class="mr-3"
          v-model="skill.title"
          required
        ></b-form-input>
        <label class="mr-3">事業所</label>
        <b-form-select
          :options="offices"
          v-model="skill.office"
          class="mr-auto"
        />
        <b-button class="mr-3" @click="newItem" variant="outline-primary"
          >項目の追加</b-button
        >
        <b-button @click="handleSubmit" variant="outline-primary"
          >保存</b-button
        >
      </div>
      <b-table :items="skill.view_point" :fields="fields">
        <template v-slot:cell(viewPoint)="data">
          <b-form-input
            name="viewPoint"
            v-model="data.item"
            required
            :state="nameState"
          ></b-form-input>
        </template>
        <template v-slot:cell(action)="data">
          <b-button @click="deleteItem(data)" variant="outline-danger"
            >削除</b-button
          >
        </template>
      </b-table>
    </form>
  </div>
</template>

<script>
import { getFirestore, doc, updateDoc, getDoc, getDocs, collection } from 'firebase/firestore';

export default {
  name: "manageSkillEdit",
  data() {
    return {
      fields: ["viewPoint", "action"],
      skill: {},
      nameState: null,
      offices: [],
    };
  },
  methods: {
    newItem () {
      this.skill.view_point.push("");
    },
    deleteItem (data) {
      this.skill.view_point = this.skill.view_point.filter((item) => {
        return item != data.item;
      });
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    async handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.skill.view_point = Array.prototype.map.call(document.getElementsByName("viewPoint"), elm => { return elm.value });
      await updateDoc(doc(getFirestore(), `skills/${this.$route.params.id}`), this.skill)
      this.$router.push("/manage/skill")
    },
  },
  async created () {
    const skill = await getDoc(doc(getFirestore(), `skills/${this.$route.params.id}`))
    this.skill = skill.data();
    this.offices = (await getDocs(collection(getFirestore(), 'offices'))).docs.map(e => { return {
      value: e.id,
      text: e.data().name
    }}).concat([{value: null, text: "未選択"}])
  },
};
</script>
