<template>
  <div>
    <b-row class="mt-5 mb-5">
      <h4 class="mr-auto"><b-icon icon="trophy-fill" aria-hidden="true"/> タグ履歴の管理</h4>
    </b-row>
    <p>
      タグは削除してもこれまでの記録には影響ありません．不要になったものは削除することができます．
    </p>
    <b-table :items="skills" :fields="fields">
      <template v-slot:cell(action)="data">
        <b-button @click="deleteItem(data)" variant="outline-danger"
          >削除</b-button
        >
      </template>
    </b-table>
  </div>
</template>

<script>
import { getFirestore, getDocs, collection, doc, deleteDoc } from 'firebase/firestore';

export default {
  name: "manageSkill",
  data() {
    return {
      fields: ["title", "action"],
      skills: [],
    };
  },
  methods: {
    async deleteItem (data) {
      await deleteDoc(doc(getFirestore(), "tags/"+data.item.id))
      location.reload()
    },
  },
  async created () {
    const snapshot = await getDocs(collection(getFirestore(), "tags"))
    this.skills = snapshot.docs.map(skillDoc => {
      return {
        id: skillDoc.id,
        title: skillDoc.data()["title"],
      }
    })
  },
};
</script>
