<template>
  <div>
    <a v-b-modal="`modal-${$_uid}`">
      <b-img id="img" :src="imageURL" fluid-grow thumbnail />
    </a>
    <b-modal :id="`modal-${$_uid}`" hide-footer hide-header centered>
      <b-img :src="imageURL" fluid-grow />
    </b-modal>
  </div>
</template>

<style lang="scss">
#img {
  height: 180px;
  object-fit: cover;
  object-position: 0% 0%;
}
</style>

<script>
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

export default {
  name: "FIRImage",
  props: {
    path: String,
  },
  data() {
    return {
      imageURL: "",
    };
  },
  async created () {
    try {
      this.imageURL= await getDownloadURL(ref(ref(getStorage(), this.path), "image.png"))
    } catch (e) {
      console.log(e);
    }
  },
};
</script>
