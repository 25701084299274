import Vue from 'vue'
import {BootstrapVue, IconsPlugin} from "bootstrap-vue"
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import { initializeApp } from "firebase/app"
import { getAuth, onAuthStateChanged } from "firebase/auth";
import router from './router'
import VueUid from "vue-uid"
import VueRouter from 'vue-router'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueUid)
Vue.use(VueRouter)

const firebaseConfig = {
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  databaseURL: process.env.DATABSE_URL,
  projectId: process.env.PROJECT_ID,
  storageBucket: process.env.STORAGE_BUCKET,
  messagingSenderId: process.env.MESSAGING_SENDER_ID,
  appId: process.env.APP_ID,
  measurementId: process.env.MEASUREMENT_ID
};
initializeApp(firebaseConfig);

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const auth = getAuth()
    const unsubscribe = onAuthStateChanged(auth, user => {
      unsubscribe()
      resolve(user)
    }, reject)
  });
};

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiredAuth) && !(await getCurrentUser())) {
    next({
      path: 'sign-in',
      query: {redirect: to.fullPath}
    })
  } else {
    next()
  }
})

new Vue({
  router,
  el: '#app',
  render: h => h(App)
}).$mount('#app')
