<template>
  <div>
    <h4 class="mt-5 mb-5">
      <b-icon icon="file-earmark-person-fill" aria-hidden="true" />
      パーソナル分析 - リスト
    </h4>
    <student-class-filter class="mb-5" v-model="selectedClass" />
    <b-table :items="students" :fields="fields">
      <template v-slot:cell(name)="data">
        <a :href="`./student/${data.item.id}`">{{ data.item.name }}</a>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  query,
  doc,
  where,
  documentId,
  orderBy
} from "firebase/firestore";
import StudentClassFilter from "@/components/StudentClassFilter.vue";

export default {
  name: "analyticsStudentSelection",
  components: {
    StudentClassFilter
  },
  data() {
    return {
      fields: ["name"],
      students: [],
      selectedClass: null
    };
  },
  watch: {
    async selectedClass() {
      if (!this.selectedClass) {
        this.students = [];
      }
      const db = getFirestore();
      const studentIds = (
        await getDoc(
          doc(
            db,
            `offices/${this.selectedClass.office}/classes/${this.selectedClass.class}`
          )
        )
      ).data().member;

      const snapshot = await getDocs(
        query(collection(db, "students"), where(documentId(), "in", studentIds))
      );
      this.students = snapshot.docs.map(doc => {
        return { id: doc.id, name: doc.data()["name"] };
      });
    }
  }
};
</script>
