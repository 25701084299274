<template>
  <div>
    <b-row class="mt-5 mb-5">
      <h4 class="mr-auto">
        <b-icon icon="trophy-fill" aria-hidden="true" /> スキルの管理
      </h4>
      <b-button @click="addNew" variant="outline-primary">新規追加</b-button>
    </b-row>
    <b-table :items="skills" :fields="fields">
      <template v-slot:cell(viewPoint)="data">
        <ul>
          <li :key="index" v-for="(item, index) in data.item.viewPoint">
            {{ item }}
          </li>
        </ul>
      </template>
      <template v-slot:cell(action)="data">
        <b-button :href="'skill/' + data.item.id" variant="outline-dark"
          >編集</b-button
        >
        <b-button
          v-b-modal="`deleteAlertFor${data.item.id}`"
          variant="outline-danger"
          >削除</b-button
        >
        <b-modal
          :id="`deleteAlertFor${data.item.id}`"
          title="削除"
          @ok="deleteItem(data)"
        >
          <p>「{{ data.item.title }}」を削除してもよろしいですか？</p>
        </b-modal>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  getFirestore,
  deleteDoc,
  doc,
  getDocs,
  collection,
  addDoc,
  orderBy,
  query
} from "firebase/firestore";

export default {
  name: "manageSkill",
  data() {
    return {
      fields: ["title", "viewPoint", "office", "action"],
      skills: [],
      offices: {}
    };
  },
  methods: {
    deleteItem: async function(data) {
      await deleteDoc(doc(getFirestore(), `skills/${data.item.id}`));
      location.reload();
    },
    addNew: async function() {
      const doc = await addDoc(collection(getFirestore(), "skills"), {
        title: "NewSkill",
        view_point: []
      });
      location.href = "skill/" + doc.id;
    }
  },
  async created() {
    const snapshot = await getDocs(
      query(collection(getFirestore(), "skills"), orderBy("title"))
    );
    (await getDocs(collection(getFirestore(), "offices"))).docs.forEach(e => {
      this.offices[e.id] = e.data().name;
    });
    console.log(this.offices);
    this.skills = snapshot.docs.map(skillDoc => {
      return {
        id: skillDoc.id,
        title: skillDoc.data()["title"],
        viewPoint: skillDoc.data()["view_point"],
        office: skillDoc.data()["office"]
          ? this.offices[skillDoc.data()["office"]]
          : ""
      };
    });
  }
};
</script>