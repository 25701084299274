import Vue from 'vue'
import Router from 'vue-router'

import AnalyticsClass from './views/analytics/Class.vue'
import AnalyticsStudent from './views/analytics/Student.vue'
import AnalyticsStudentSelection from './views/analytics/StudentSelection.vue'
import NotFound from './views/NotFound.vue'
import ManageOffice from './views/manage/Office.vue'
import ManageClass from './views/manage/Class.vue'
import ManageClassMember from './views/manage/ClassMember.vue'
import ManageStaff from './views/manage/Staff.vue'
import ManageSkill from './views/manage/Skill.vue'
import ManageSkillEdit from './views/manage/SkillEdit.vue'
import ManageStudent from './views/manage/Student.vue'
import SignIn from './views/SignIn.vue'
import Index from './views/Index.vue'
import ManageTags from './views/manage/Tags.vue'

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/analytics/class',
            name: 'analyticsClass',
            component: AnalyticsClass,
            meta: { requiredAuth: true }
        },
        {
            path: '/analytics/class/:officeId/:classId',
            name: 'analyticsClass',
            component: AnalyticsClass,
            meta: { requiredAuth: true }
        },
        {
            path: '/analytics/student',
            name: 'analyticsStudentSelection',
            component: AnalyticsStudentSelection,
            meta: { requiredAuth: true }
        },
        {
            path: '/analytics/student/:id',
            name: 'analyticsStudent',
            component: AnalyticsStudent,
            meta: { requiredAuth: true }
        },
        {
            path: '/manage/office',
            name: 'manageOffice',
            component: ManageOffice,
            meta: { requiredAuth: true }
        },
        {
            path: '/manage/class/:officeId/:classId',
            name: 'manageClassMember',
            component: ManageClassMember,
            meta: { requiredAuth: true }
        },
        {
            path: '/manage/class',
            name: 'manageClass',
            component: ManageClass,
            meta: { requiredAuth: true }
        },
        {
            path: '/manage/skill',
            name: 'manageSkill',
            component: ManageSkill,
            meta: { requiredAuth: true }
        },
        {
            path: '/manage/tags',
            name: 'manageTags',
            component: ManageTags,
            meta: { requiredAuth: true }
        },
        {
            path: '/manage/skill/:id',
            name: 'manageSkillEdit',
            component: ManageSkillEdit,
            meta: { requiredAuth: true }
        },
        {
            path: '/manage/staff',
            name: 'manageStaff',
            component: ManageStaff,
            meta: { requiredAuth: true }
        },
        {
            path: '/manage/student',
            name: 'manageStudent',
            component: ManageStudent,
            meta: { requiredAuth: true }
        },
        {
            path: '/sign-in',
            name: 'signIn',
            component: SignIn
        },
        {
            path: '/',
            name: 'index',
            component: Index,
            meta: { requiredAuth: true }
        },
        {
            path: '*',
            name: 'notFound',
            component: NotFound,
        }, 
    ]
})