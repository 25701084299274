<template>
  <div>
    <b-row class="mt-5 mb-5">
      <h4 class="mr-auto">
        <b-icon icon="person-fill" aria-hidden="true" /> スタッフの管理
      </h4>
      <b-button
        class="mr-3"
        variant="outline-primary"
        v-b-modal.modal-prevent-closing
        >新規追加
      </b-button>
    </b-row>
    <b-table :items="staffs" :fields="fields">
      <template v-slot:cell(action)="data">
        <b-button
          v-b-modal="`deleteAlertFor${data.item.id}`"
          variant="outline-danger"
          >削除</b-button
        >
        <b-modal
          :id="`deleteAlertFor${data.item.id}`"
          title="削除"
          @ok="deleteItem(data)"
        >
          <p>「{{ data.item.name }}」を削除してもよろしいですか？</p>
        </b-modal>
      </template>
    </b-table>
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="新規追加"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="nameState"
          label="名前"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="newName"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { } from 'firebase/compat/firestore';
import { collection, getFirestore, deleteDoc, doc, getDocs, addDoc } from '@firebase/firestore';

export default {
  name: "manageStaff",
  data() {
    return {
      newName: "",
      nameState: null,
      fields: ["name", "action"],
      staffs: [],
    };
  },
  methods: {
    async deleteItem(data) {
      await deleteDoc(doc(getFirestore(), `staffs/${data.item.id}`))
      location.reload();
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.newOfficeName = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    async handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      await addDoc(collection(getFirestore(), "staffs"), { name: this.newName })
      location.reload();
    },
  },
  async created() {
    const snapshot = await getDocs(collection(getFirestore(), 'staffs'))
    this.staffs = snapshot.docs.map((staffDoc) => {
      return {
        id: staffDoc.id,
        name: staffDoc.data()["name"],
      }
    })
  }
};
</script>
