<template>
  <div>
    <b-card class="mt-3">
      <b-card-body>
        <b-card-title><b-icon icon="wrench"/> システムのアップデート</b-card-title>
      </b-card-body>
      <b-list-group flush>
        <b-list-group-item>2021年5月21日 タグ機能の追加・Excel出力の修正</b-list-group-item>
        <b-list-group-item>2020年11月11日 グラフ表示の最適化・平均の追加</b-list-group-item>
        <b-list-group-item>2020年10月23日 Excelへの書き出し機能の追加</b-list-group-item>
        <b-list-group-item>2020年10月23日 個人分析ページの実装</b-list-group-item>
        <b-list-group-item>2020年10月22日 トップページの用意</b-list-group-item>
        <b-list-group-item
          >2020年10月21日
          クラス別分析でのハイライト表示，CSV書き出し機能の追加</b-list-group-item
        >
      </b-list-group>
    </b-card>

    <b-card class="mt-3">
      <b-card-body>
        <b-card-title><b-icon icon="bookmark-fill"/> 新着のレッスン</b-card-title>
        <b-card-text>最近、以下のレッスンが行われました</b-card-text>
      </b-card-body>
      <b-list-group flush>
        <b-list-group-item v-for="(lesson, key) in lessons" :key="key">
          {{ lesson.date.toDate().toLocaleDateString() }}　{{ lesson.class.name }}クラスで「{{ lesson.skill ? lesson.skill.title : "未設定" }}」のレッスン
        </b-list-group-item>
      </b-list-group>
    </b-card>

    <b-card-group class="mt-3" deck>
      <b-card :img-src="studentIcon" img-top>
        <b-card-body>
          <b-card-title>パーソナル分析</b-card-title>
          <b-card-text>個人別にスキル毎の分析結果を表示します</b-card-text>
          <b-button href="./analytics/student" variant="primary"
            >分析を開く</b-button
          >
        </b-card-body>
      </b-card>
      <b-card :img-src="classIcon" img-top>
        <b-card-body>
          <b-card-title>クラス分析</b-card-title>
          <b-card-text
            >期間を指定してクラス全体の分析結果を表示します</b-card-text
          >
          <b-button href="./analytics/class" variant="primary"
            >分析を開く</b-button
          >
        </b-card-body>
      </b-card>
      <b-card :img-src="settingsIcon" img-top>
        <b-card-body>
          <b-card-title>管理</b-card-title>
          <b-card-text>クラスやメンバーについての設定をはじめます</b-card-text>
          <b-card-text>上のバーから設定する項目を選んでください</b-card-text>
        </b-card-body>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import studentIcon from "@/assets/studentIcon.png";
import classIcon from "@/assets/classIcon.png";
import settingsIcon from "@/assets/settingsIcon.png";
import { getFirestore, collection, query, orderBy, limit, getDocs } from 'firebase/firestore';

export default {
  name: "index",
  data() {
    return {
      studentIcon: studentIcon,
      classIcon: classIcon,
      settingsIcon: settingsIcon,
      lessons: {},
    };
  },
  created: async function () {
    const db = getFirestore()
    const collectionRef = collection(db, 'lessons')
    const q = query(collectionRef, orderBy("date"), limit(3))
    const snapshot = await getDocs(q)
    this.lessons = snapshot.docs.map(doc => doc.data())
  },
};
</script>
