<template>
  <div id="app">
    <SSHeader></SSHeader>
    <b-container>
      <router-view />
    </b-container>
    <SSFooter></SSFooter>
  </div>
</template>

<script>
import SSHeader from "./components/SSHeader.vue";
import SSFooter from "./components/SSFooter.vue";

export default {
  name: "app",
  components: {
    SSHeader,
    SSFooter,
  },
};
</script>

<style lang="scss">
html,body {
  background-color: #10375c;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #f4f6ff;
}
</style>
